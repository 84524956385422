<template>
  <div> 

    <b-form-group id="input-Country" label-for="Country" class="col-8" v-if="ShowCountry">
        <b-form-select @change="EmitCountry($event)" 
          v-model="FormCountry" 
          :options="RespReadAllCountry">
        </b-form-select>
    </b-form-group>

    <b-form-group id="input-State" label-for="State" class="col-8" v-if="ShowCountry">
        <b-form-select @change="EmitState($event)" 
          v-model="FormState" 
          :options="RespReadAllState">
        </b-form-select>
    </b-form-group>

    <b-form-group id="input-City" label-for="City" class="col-8" v-if="ShowCountry">
        <b-form-select @change="EmitCity($event)" 
          v-model="FormCity" 
          :options="RespReadAllCity">
        </b-form-select>
    </b-form-group>                           

  </div>
</template>

<script>
import Vue from 'vue'
import Vueaxios from 'vue-axios'
import axios from 'axios'

Vue.use(Vueaxios,axios)
  export default {
  props: {
    ShowCountry: Boolean,
    ShowState: Boolean,
    ShowCity: Boolean,  
  },    
    data() {
      return {
      // Input form variables
      FormCountry: 'default',
      FormState: 'default', 
      FormCity: 'default', 
      // Axios Resp array variables
      RespReadAllCountry: [{ value: 'default', text: 'Select Country' }],
      RespReadAllState: [{ value: 'default', text: 'Select State' }],      
      RespReadAllCity: [{ value: 'default', text: 'Select City' }],      
      }
    },
    mounted()
    {
      this.ReadAllCountry()
    },    
    methods: {
      onSubmit(event) {
        event.preventDefault()
      },

      EmitCountry() {
          console.log(this.FormCountry)
          this.ReadAllStates(this.FormCountry)
          this.$emit('EmitCountry', this.FormCountry)
        },

      EmitState() {
          console.log(this.FormState)
          this.ReadAllCities(this.FormState)
          this.$emit('EmitState', this.FormState)
        },

      EmitCity() {
          console.log(this.FormCity)
          this.$emit('EmitCity', this.FormCity)
        },                
      
      ReadAllCountry(){  
        Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=ReadAllCountry')
        .then((resp)=>{
        // this.RespArtsCatg.push(resp.data.ReadAllCountry);
          resp.data.ReadAllCountry.forEach(element => this.RespReadAllCountry.push(element))
      })
      },

      ReadAllStates(GetCountry){
        this.RespReadAllState.splice(0);
        this.FormState= 'default', 
        this.RespReadAllState.push({ value: 'default', text: 'Select State' })           
        const REQformData1 = new FormData();
        REQformData1.append('country_id', GetCountry);
        Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=ReadAllStates', REQformData1)
        .then((resp)=>{
        // this.RespArtsCatg.push(resp.data.ReadAllStates);
          resp.data.ReadAllStates.forEach(element => this.RespReadAllState.push(element))
      })
      },

      ReadAllCities(GetCity){
        this.RespReadAllCity.splice(0);
        this.FormCity= 'default', 
        this.RespReadAllCity.push({ value: 'default', text: 'Select City' })   
        const REQformData2 = new FormData();
        REQformData2.append('state_id', GetCity);
        Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=ReadAllCities', REQformData2)
        .then((resp)=>{
        // this.RespArtsCatg.push(resp.data.ReadAllCities);
          resp.data.ReadAllCities.forEach(element => this.RespReadAllCity.push(element))
      })
      },       
       
     ClearForms(){  
        this.FormCountry = 'default';   
        this.FormState = 'default'
        this.FormCity = 'default'    
      },        
                 
      onReset(event) {
        event.preventDefault()
        this.ClearForms()
        // Trick to reset/clear native browser form validation state
        this.$nextTick(() => {
        })
      },
    },

    computed: {
    }    
  }
</script>

