<template>
    <div>
        <h3 class="sidebar-title">Recent Event Gallery</h3>
        <div class="sidebar-item recent-posts" v-for="Data in RecentData.slice(0, 6)" v-bind:key="Data.id">
            <div class="post-item clearfix mt-4">
              <router-link :to="{name:'events-gallery-detail', params:{ EgallId:Data.egall_id}}"><img class="rounded-circle float-left" v-bind:src="Data.egall_logo" :alt="Data.egall_tittle"></router-link>
              <h4><router-link :to="{name:'events-gallery-detail', params:{ EgallId:Data.egall_id}}">{{Data.egall_tittle}}</router-link></h4>
              <p class="tag-line">{{Data.egall_desc}}</p>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import Vueaxios from 'vue-axios'
import axios from 'axios'

Vue.use(Vueaxios,axios)

  export default {
    data() {
      return {
        RecentData: [],
        }
      },
  watch: {
    '$route' (to)
    {
      this.ReadDtltroupe(to.params.EgallId);
    }
  },        
    mounted()
    {
      this.ReadRecentGallery()
    },
    methods: {   
        ReadRecentGallery(){
        Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=ReadRecentGallery')
        .then((resp)=>{
        this.RecentData=resp.data.RecentGallery;
      })
      },          
    },
    computed: {      
  }
  }
</script>

<style scoped>
.recent-perform img {
    width: 100px;
    float: center;
    height: 100px;
    padding: 2px 5px 2px 5px;
    border-radius: 15px;

}
</style>
