<template>
  <div>

    <!-- ======= Contact Section ======= -->
    <section class="contact" >
      <div class="container">
        <div class="section-title">
            <h2>Contact us</h2>
            <!-- <p>Send an email to info@thiraseela.com for faster response.</p> -->
        </div>        
        <div class="row">
          <div class="col-lg-6">
            <div class="row">
              <div class="col-md-6">
                <div class="info-box">
                  <i class="bx bx-map"></i>
                  <h3>Our Address</h3>
                  <p>Kerala, India | Pennsylvania, USA</p>
                </div>
              </div>
              <div class="col-md-6">
                <div class="info-box">
                  <i class="bx bx-envelope"></i>
                  <h3>Email Us</h3>
                  <p>info@thiraseela.com</p>
                </div>
              </div>
              <!-- <div class="col-md-12 align-content-center">
                <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fthiraseela%2F&tabs&width=340&height=130&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=297157873768111" width="500" height="130" style="border:none;" scrolling="no" frameborder="0" allowfullscreen="true" data-show-facepile="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
              </div> -->
            </div>

          </div>          
          <div class="col-lg-6">
            <b-form @submit.prevent="sendEmail" @reset="onReset" v-if="show">
              <b-form-group id="YourName">
                <b-form-input
                  id="YourName"
                  v-model="user_name"
                  name="user_name"
                  placeholder="Enter Your name"
                  required
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="YourEmail"
                description="We'll never share your email with anyone else."
              >
                <b-form-input
                  id="YourEmail"
                  v-model="user_email"
                  name="user_email"
                  type="email"
                  placeholder="Enter Your email"
                  required
                ></b-form-input>
              </b-form-group>
              
              <b-form-group id="YourSubject">
                <b-form-input
                  id="Subject"
                  v-model="subject"
                  name="subject"
                  placeholder="Enter the subject"
                  required
                ></b-form-input>
              </b-form-group>              

              <b-form-textarea
                id="textarea"
                v-model="message"
                name="message"
                placeholder="Enter your message..."
                rows="3"
                max-rows="6"
              ></b-form-textarea>

                <div cols="12" class="my-3 ">
                  <b-button pill type="submit" variant="primary">Submit</b-button>
                  <b-button pill type="reset" variant="danger">Reset</b-button>
                </div>
            </b-form>

          </div>
        </div>
      </div>
  </section>
    <!-- End Contact Section -->    

  </div>
</template>



<script>
import emailjs from 'emailjs-com';

export default {
    data() {
      return {
        user_email: '',
        user_name: '',
        subject: '',
        message: '',
        show: true
      }
    },  
  methods: {
    sendEmail(e) {
      emailjs.sendForm('service_ucq3yji', 'template_mili2vt', e.target, 'user_DW3ZBRMOzHyQXkqdcP6bH')
        .then(() => {
            // console.log('SUCCESS!', result.status, result.text);
            alert('Thanks! Your message has been sent successfully!')
        }, (error) => {
            console.log('FAILED...', error);
            alert('FAILED! Something wrong! Please send an email to kiranvt06@gmail.com!')
        });
        this.ClearForms()
    },
      // onSubmit(event) {
      //   event.preventDefault()
      //   alert(JSON.stringify(this.form))
      //   this.sendEmail()
      // },
      onReset(event) {
        event.preventDefault()
        this.ClearForms()
        // Trick to reset/clear native browser form validation state
        this.show = false
        this.$nextTick(() => {
          this.show = true
        })
      },

      ClearForms(){
        this.user_email = ''
        this.user_name = ''
        this.subject = ''
        this.message = ''
      },                  
  }
}
</script>
