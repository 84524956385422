<template>
  <div> 

    <b-form class="col-8">
    <b-form-group id="input-ShowType" label-for="ShowType" >
        <b-form-select @change="GetAllProfiles();EmitShowType($event)" 
          v-model="FormShowType" 
          :options="ListShowType">
        </b-form-select>
    </b-form-group>
    </b-form>

    <b-form class="col-8">
      <b-form-group id="input-group-2" label-for="ShowBy">
        <b-form-input list="my-list-id"
          @change="EmitShowBy($event)"
          id="ShowBy"
          v-model="FormShowBy"
          placeholder="Search & select your Profile by name"
        ></b-form-input>                
      </b-form-group>
    </b-form>

    <datalist id="my-list-id">
      <option>Not found? Enter Artist/Troupe/Band name </option>
      <option v-for="Data in RespAllProfiles" v-bind:key="Data.profile_id">{{ Data.profile_name }}</option>
    </datalist>    

  </div>
</template>

<script>
import Vue from 'vue'
import Vueaxios from 'vue-axios'
import axios from 'axios'

Vue.use(Vueaxios,axios)
  export default {
  props: {
    // ShowMultpleCatg: Boolean,
    // ShowMainCatg: Boolean,
  },    
    data() {
      return {
      // Input form variables
      FormShowType: 'default',
      FormShowBy: '',
      RespAllProfiles: [],
      ListShowType: [
        { value: 'default', text: 'Select program Type' },
        { value: 'T', text: 'Troupe/Band'},
        { value: 'A', text: 'School/Academy'},
        { value: 'P', text: 'Artist/Performer'},
      ],                   
      }
    },
    mounted()
    {
      this.GetAllProfiles()
    },    
    methods: {

      EmitShowBy() {
          console.log(this.FormShowBy)
          this.$emit('EmitShowBy', this.FormShowBy)
        },

      EmitShowType() {
          console.log(this.FormShowBy)
          this.$emit('EmitShowType', this.FormShowType)
        },           
      
      GetAllProfiles(){
        const REQformData = new FormData();
        REQformData.append('profile_type', this.FormShowType);
        // console.log(this.FormShowType)
        Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=GetAllProfiles',REQformData)
        .then((resp)=>{
        this.RespAllProfiles=resp.data.GetAllProfiles;
      })
      },        
       
     ClearForms(){  
        this.FormShowType = 'default'
        this.RespAllProfiles = []    
      },        
                 
      onReset(event) {
        event.preventDefault()
        this.ClearForms()
        // Trick to reset/clear native browser form validation state
        this.$nextTick(() => {
        })
      },
    },

    computed: {
    }    
  }
</script>