<template>
    <div>
        <h3 class="sidebar-title">Related Stage Shows</h3>
        <div class="sidebar-item recent-posts" v-for="Data in RecentData.slice(0, 8)" v-bind:key="Data.seq_no">
          <div class=" clearfix mt-4">
            <router-link :to="{name:'stage-shows-detail', params:{ ShowId:Data.seq_no, SlugName:Data.slug_name}}"><img class="rounded-circle float-left" v-bind:src="Data.logo" :alt="Data.show_name"></router-link>
            <h4><router-link :to="{name:'stage-shows-detail', params:{ ShowId:Data.seq_no, SlugName:Data.slug_name}}">{{Data.show_name}}</router-link></h4>
            <p class="tag-line">{{Data.show_owner}}</p>
          </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import Vueaxios from 'vue-axios'
import axios from 'axios'

Vue.use(Vueaxios,axios)

  export default {
    data() {
      return {
        RecentData: [],
        }
      },      
    mounted()
    {
      this.ReadRecentStageShows()
    },
    methods: {   
        ReadRecentStageShows(){
        Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=ReadRecentStageShows')
        .then((resp)=>{
        this.RecentData=resp.data.RecentStageShows;
      })
      },          
    },
    computed: {      
  }
  }
</script>

<style scoped>

.page-title-text {
  color:#e75480;
  font-weight:800;
  font-size:16px;
  margin-left:10px;
}

.page-title-text p {
    margin-top: -25px;
    font-style: italic;
    font-size:14px;
    font-weight:300;
    color: #72afce;
}
</style>