import firebase from '@firebase/app'
import 'firebase/firestore'
import 'firebase/firebase-auth'
import 'firebase/storage'

const firebaseConfig = {
    apiKey: "AIzaSyD1llLHrHqCY0GAhQm_hy8dkBI5AdluL6M",
    authDomain: "thiraseela.firebaseapp.com",
    projectId: "thiraseela",
    storageBucket: "thiramedia",
    messagingSenderId: "69269203570",
    appId: "1:69269203570:web:cae53428c5c38a0b7c30eb",
    measurementId: "G-D24XWQTVEN"
};

// Initialize Firebase
const fb = firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();
export { fb, db }