<template>
    <div>
              <div class="sidebar">
                <div>
                  <!-- <h3 class="sidebar-title">Submit Your Portfolio!</h3> -->
                  <div class="sidebar-item tags">
                    <ul>
                      <li v-if="user.authenticated"><router-link :to="{name:'dashboard'}"> My Dashboard</router-link></li>                  
                    </ul>
                  </div>
                </div>                
                <div>
                  <h3 class="sidebar-title">Submit Your Portfolio!</h3>
                  <div class="sidebar-item tags">
                    <ul>
                      <li><router-link :to="{name:'submit-user-portfolio'}">Create Artist Portfolio</router-link></li>
                      <li><router-link :to="{name:'submit-troupe-portfolio'}">Create Troupe Portfolio</router-link></li>
                      <li><router-link :to="{name:'submit-academy-portfolio'}">Create Academy Portfolio</router-link></li>
                      <h3 class="sidebar-title mt-3">Promote Shows & Trainings</h3>                      
                      <li><router-link :to="{name:'submit-stage-shows'}">Promote Your Stage Shows</router-link></li>
                      <li><router-link :to="{name:'submit-promotions'}">Promote Your Trainings</router-link></li>
                      <h3 class="sidebar-title mt-3">Submit Your Events & Photos!</h3>
                      <li><router-link :to="{name:'submit-events'}">Submit Your Upcoming Events</router-link></li>
                      <li><router-link :to="{name:'submit-photos'}">Publish Your Event Photo</router-link></li>
                      <li><router-link :to="{name:'submit-achievements'}">Share Your Achievements</router-link></li>   
                      <h3 class="sidebar-title mt-3">Contact us!</h3>   
                      <li><router-link :to="{name:'contact-us'}">Contact us</router-link></li>               
                    </ul>
                  </div>
                </div>
                <!-- sidebar Tags-->
              </div>  
    </div><!-- End Main Div  -->
</template>

<script>
import { mapGetters } from "vuex";

  export default {
    data() {
      return {
      // Input form variables
      }
    },
  computed: {
    // map `this.user` to `this.$store.getters.user` 
    ...mapGetters({
      user: "user"
    })
    }       
  }
</script>