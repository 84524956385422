<template>
    <div>
      <!-- sidebar Categories -->
      <h3 class="sidebar-title">Events By Category</h3>
      <div class="sidebar-item categories">
        <ul v-for="Cnts in RespEventsCnts.slice(0, 10)" v-bind:key="Cnts.id">
          <li><router-link :to="{name:'events',params:{ Maincatg:Cnts.event_Catg}}"><a> {{Cnts.event_Catg}}<span> ({{ Cnts.rec_count}})</span></a></router-link></li>
        </ul>
      </div> 
    </div>
</template>

<script>
import Vue from 'vue'
import Vueaxios from 'vue-axios'
import axios from 'axios'

Vue.use(Vueaxios,axios)

  export default {
    data() {
      return {
        RespEventsCnts: [],      
        }
      },    
    mounted()
    {
      this.CntEventsByCatg();
    },
    methods: {
      CntEventsByCatg(){
        Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=CntEventsByCatg')
        .then((resp)=>{
        this.RespEventsCnts=resp.data.CntEventsByCatg;
      })
      },                     
    },
  }
</script>
