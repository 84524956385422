<template>
    <div id="RightSideBar">
        <h3 class="sidebar-title">Professional Troupes</h3>
        <div class="sidebar-item recent-posts" v-for="Data in RecentData.slice(0,8)" v-bind:key="Data.id">
          <div class="post-item clearfix mt-4">
            <router-link :to="{name:'troupes-detail', params:{ TroupeId:Data.seq_no, SlugName:Data.slug_name}}"><img class="rounded-circle float-left" v-bind:src="Data.troupe_photo" :alt="Data.troupe_name"></router-link>
            <h4><router-link :to="{name:'troupes-detail', params:{ TroupeId:Data.seq_no, SlugName:Data.slug_name}}">{{Data.troupe_name}}</router-link></h4>
            <p class="tag-line">{{Data.troupe_pgm_name}}</p>
          </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import Vueaxios from 'vue-axios'
import axios from 'axios'

Vue.use(Vueaxios,axios)

  export default {
    data() {
      return {
        RecentData: [],
        }
      },
  watch: {
    '$route' (to)
    {
      this.RecentTroupe(to.params.TroupeId);
    }
  },        
    mounted()
    {
      this.RecentTroupe()
    },
    methods: {   
        RecentTroupe(){
        Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=RecentTroupe')
        .then((resp)=>{
        this.RecentData=resp.data.RecentTroupe;
      })
      },          
    },
    computed: {      
  }
  }
</script>