<template>
    <div>
        <h3 class="sidebar-title">Online Classes</h3>
        <div class="sidebar-item recent-posts" v-for="Data in RecentData.slice(0, 8)" v-bind:key="Data.id">
            <div class="post-item clearfix mt-4">
              <router-link :to="{name:'online-classes-detail', params:{ SeqNo:Data.seq_no, SlugName:Data.slug_name}}"><img class="rounded-circle float-left" v-bind:src="Data.logo" :alt="Data.promo_title"></router-link>
              <h4><router-link :to="{name:'online-classes-detail', params:{ SeqNo:Data.seq_no, SlugName:Data.slug_name}}">{{Data.promo_title}}</router-link></h4>
              <p class="tag-line">{{Data.promo_type}} By {{Data.owner_id}}</p>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import Vueaxios from 'vue-axios'
import axios from 'axios'

Vue.use(Vueaxios,axios)

  export default {
    data() {
      return {
        RecentData: [],
        }
      },        
    mounted()
    {
      this.RecentOnlineClasses()
    },
    methods: {   
        RecentOnlineClasses(){
        Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=RecentOnlineClasses')
        .then((resp)=>{
        this.RecentData=resp.data.RecentOnlineClasses;
      })
      },            
    },
    computed: {      
  }
  }
</script>
