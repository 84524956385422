import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);
export default new Vuex.Store({
    state: {

        user: {
            data: 'thira',
            authenticated: false,
        }
    },
    getters: {
        user(state) {
            return state.user
        }
    },
    mutations: {
        setAuthentication(state, status) {
            state.user.authenticated = status;
            // console.log('setAuthentication', state.user.authenticated)
        },
        SET_USER(state, data) {
            state.user.data = data;
        }
    },
    actions: {
        fetchUser({ commit }, user) {
            // console.log('fetchUser Actions executed')
            // console.log('user data', user)
            if (user) {
                // console.log('User Data FOUND')
                commit("setAuthentication", true);
                commit("SET_USER", {
                    displayName: user.displayName,
                    email: user.email
                });
            } else {
                // console.log('User Data NOT FOUND')
                commit("SET_USER", null);
                commit("setAuthentication", false);
            }
        }
    }
});