<template>
    <div>
  <!-- ======= Hero Section ======= -->
  <section id="hero" class="d-flex justify-cntent-center align-items-center">
    <div id="heroCarousel" class="container carousel carousel-fade" data-ride="carousel">

      <!-- Slide 1 -->
      <div class="carousel-item active">
        <div class="carousel-container">
          <h2 class="animated fadeInDown">Welcome to <span class="color-pink">Thiraseela</span></h2>
          <p class="animated fadeInUp">An online platform to promote performing arts, performers and stage shows! An one-stop solution for event bookings and other stage shows. Thiraseela, for you is making an attempt to showcase your talents and publishing it across the globe.</p>
          <a class="btn-get-started animated fadeInUp"><router-link :to="{name:'About-us'}">Read More!</router-link></a>
        </div>
      </div>

      <!-- Slide 3 -->
      <div class="carousel-item">
        <div class="carousel-container">
          <h2 class="animated fadeInDown">Book <span class="color-pink">Stage Shows</span></h2>
          <p class="animated fadeInUp">Thiraseela brings you the best entertainers & performers for all types of events, festivals and parties. Search through our program/artist directories and choose the best program for your next event.</p>
          <a class="btn-get-started animated fadeInUp"><router-link :to="{name:'stage-shows',params:{ Maincatg:'All'}}">Book Programs!</router-link></a>
        </div>
      </div>

      <div class="carousel-item">
        <div class="carousel-container">
          <h2 class="animated fadeInDown">Online <span class="color-pink">Classes</span></h2>
          <p class="animated fadeInUp">Want to learn an art form online? Thiraseela connects you with the best dance, music and theatre artists/academies from which you can kick start your passion. </p>
          <a class="btn-get-started animated fadeInUp"><router-link :to="{name:'online-classes',params:{ Maincatg:'All'}}">Enroll now!</router-link></a>
        </div>
      </div>      

      <!-- Slide 2 -->
      <div class="carousel-item">
        <div class="carousel-container">
          <h2 class="animated fadeInDown">Performers <span class="color-pink">Portfolio</span></h2>
          <p class="animated fadeInUp">Thousands of Performing Artists portfolios! Thiraseela, for you is making an attempt to showcase your talents and publishing it across the globe. </p>
          <a class="btn-get-started animated fadeInUp"><router-link :to="{name:'performers',params:{ Maincatg:'All'}}">Search Now!</router-link></a>
        </div>
      </div>      

      <a class="carousel-control-prev" href="#heroCarousel" role="button" data-slide="prev">
        <span class="carousel-control-prev-icon bx bx-chevron-left" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>

      <a class="carousel-control-next" href="#heroCarousel" role="button" data-slide="next">
        <span class="carousel-control-next-icon bx bx-chevron-right" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>

    </div>
  </section><!-- End Hero -->
    </div>
</template>

<script>
import $ from 'jquery'
export default {
  mounted () {
    this.getjs()
  },
  methods: {
    getjs() {
      $(document).ready(function () {
    // Intro carousel
    var heroCarousel = $("#heroCarousel");

    heroCarousel.on('slid.bs.carousel', function() {
        $(this).find('h2').addClass('animated fadeInDown');
        $(this).find('p').addClass('animated fadeInUp');
        $(this).find('.btn-get-started').addClass('animated fadeInUp');
    });
    // Back to top button
    $(window).scroll(function() {
        if ($(this).scrollTop() > 100) {
            $('.back-to-top').fadeIn('slow');
        } else {
            $('.back-to-top').fadeOut('slow');
        }
    });

    $('.back-to-top').click(function() {
        $('html, body').animate({
            scrollTop: 0
        }, 1500, 'easeInOutExpo');
        return false;
    });    
    // Function ends here.
    }
      )}
}
}
</script>