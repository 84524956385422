<template>
  <div> 

    <b-form-group id="input-MainCatg" label-for="MainCatg" class="col-8" v-if="ShowMainCatg">
        <b-form-select @change="EmitMainCatg($event)" 
          v-model="FormMainCatg" 
          :options="RespArtsMainCatg">
        </b-form-select>
    </b-form-group>    

    <b-form-group id="input-MultpleCatg" label-for="MultpleCatg" class="col-8" v-if="ShowMultpleCatg">
        <b-form-select @change="EmitMultpleCatg($event)" 
          v-model="FormMultpleCatg" 
          :options="RespMultpleCatg" 
          multiple 
          :select-size="5">
        </b-form-select>
    </b-form-group>

  </div>
</template>

<script>
import Vue from 'vue'
import Vueaxios from 'vue-axios'
import axios from 'axios'

Vue.use(Vueaxios,axios)
  export default {
  props: {
    ShowMultpleCatg: Boolean,
    ShowMainCatg: Boolean,
  },    
    data() {
      return {
      // Input form variables
      FormMainCatg: 'default', 
      FormMultpleCatg: [], 
      RespArtsMainCatg: 
      [
        { value: 'default', text: 'Select Main Category' },
      ],
      RespMultpleCatg: 
      [
        { value: 'default', text: 'SELECT 5 more related categories', disabled: true },
      ],            
      }
    },
    mounted()
    {
      this.ReadArtsMainCatg()
    },    
    methods: {
      onSubmit(event) {
        event.preventDefault()
      },

      EmitMainCatg() {
          // console.log(this.FormMainCatg)
          this.$emit('EmitMainCatg', this.FormMainCatg)
        },
      EmitMultpleCatg() {
          // console.log(this.FormMultpleCatg)
          this.$emit('EmitMultpleCatg', this.FormMultpleCatg)
        },         
      
      ReadArtsMainCatg(){  
        Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=ReadArtsMainCatg')
        .then((resp)=>{
        // this.RespArtsCatg.push(resp.data.ReadArtsMainCatg);
          resp.data.ReadArtsMainCatg.forEach(element => this.RespArtsMainCatg.push(element))
          resp.data.ReadArtsMainCatg.forEach(element => this.RespMultpleCatg.push(element))
      })
      },        
       
     ClearForms(){  
        this.FormMainCatg = 'default';   
        this.FormMultpleCatg = 'default'    
      },        
                 
      onReset(event) {
        event.preventDefault()
        this.ClearForms()
        // Trick to reset/clear native browser form validation state
        this.$nextTick(() => {
        })
      },
    },

    computed: {
    }    
  }
</script>

