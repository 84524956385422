<template>
    <div>
      <!-- sidebar Categories -->
      <h3 class="sidebar-title mb-4">Search Artist By</h3>
      <div class="sidebar-item categories">
        <ul v-for="Cnts in RespProfileCnts.slice(0, 8)" v-bind:key="Cnts.id">
          <li><router-link :to="{name:'performers',params:{ Maincatg:Cnts.catg}}"><a> {{Cnts.catg}}<span> ({{ Cnts.rec_count}})</span></a></router-link></li>
        </ul>
      </div> 
    </div>
</template>

<script>
import Vue from 'vue'
import Vueaxios from 'vue-axios'
import axios from 'axios'

Vue.use(Vueaxios,axios)

  export default {
    data() {
      return {
        RespProfileCnts: [],      
        }
      },    
    mounted()
    {
      this.ReadCountProfileByCatg();
    },
    methods: {
      ReadCountProfileByCatg(){     
        Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=ReadCountProfileByCatg')
        .then((resp)=>{
        this.RespProfileCnts=resp.data.CountProfileByCatg;
      })
      },                 
    },
  }
</script>
