<template>
    <div>
      <section class="recent-perform section-bg" >
        <div class="container">  
        <div class="row ">
          <div class="col-lg-12 section-title">
          <h2>Events Gallery</h2>
        </div>           
          <div class="col-lg-4 d-flex flex-column justify-content-center p-4" v-for="Data in RecentData.slice(0, 6)" v-bind:key="Data.id">
            <div class="clearfix">
              <router-link :to="{name:'events-gallery-detail', params:{ EgallId:Data.egall_id}}"><img class="float-left" v-bind:src="Data.egall_logo" :alt="Data.egall_tittle"></router-link>
              <h4><router-link :to="{name:'events-gallery-detail', params:{ EgallId:Data.egall_id}}">{{Data.egall_tittle}}</router-link></h4>
              <p class="tag-line" v-html="Data.egall_desc"></p>
            </div>
          </div>
          <div class="row view-all">
            <router-link to="/events-gallery">View All...</router-link>
          </div>            
        </div>
        </div>
      </section>
    </div>
</template>

<script>
import Vue from 'vue'
import Vueaxios from 'vue-axios'
import axios from 'axios'

Vue.use(Vueaxios,axios)

  export default {
    data() {
      return {
        RecentData: [],
        }
      },
  watch: {
    '$route' (to)
    {
      this.ReadDtltroupe(to.params.EgallId);
    }
  },        
    mounted()
    {
      this.ReadRecentGallery()
    },
    methods: {   
        ReadRecentGallery(){
        Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=ReadRecentGallery')
        .then((resp)=>{
        this.RecentData=resp.data.RecentGallery;
      })
      },          
    },
    computed: {      
  }
  }
</script>

<style scoped>

.recent-perform img {
    width: 100px;
    float: center;
    height: 100px;
    padding: 2px 5px 2px 5px;
    border-radius: 15px;

}

/* .recent-perform {
    margin-top: 1px;
} */

.recent-perform h4 {
    font-size: 15px;
    font-weight: bold;
}

.recent-perform h4 a {
    color: #e75480;
    transition: 0.3s;
}

.recent-perform h4 a:hover {
    color: #72afce;
}

.recent-perform .tag-line {
    display: block;
    margin-top: -5px;
    /* font-style: italic; */
    font-size: 14px;
    /* color: #72afce; */
}
</style>