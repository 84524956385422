import Vue from 'vue'
import App from './App.vue'
import Vuex from "vuex"
import router from './router'
import store from "./store";
import VueMeta from "vue-meta"
import VueAnalytics from 'vue-analytics'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueLazyLoad from 'vue-lazyload'
import jQuery from 'jquery'
global.jQuery = jQuery
global.$ = jQuery

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@fortawesome/fontawesome-free/css/all.min.css'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
    // Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.use(VueLazyLoad)
Vue.use(Vuex)
Vue.use(VueMeta, {
    keyName: "metaInfo",
    attribute: "data-vue-meta",
    ssrAttribute: "data-vue-meta-server-rendered",
    tagIDKeyName: "vmid",
    refreshOnceOnNavigation: true
});

//Vendor CSS Files -->
import './assets/vendor/bootstrap/css/bootstrap.min.css'
import './assets/vendor/animate.css/animate.min.css'
import './assets/vendor/icofont/icofont.min.css'
import './assets/vendor/boxicons/css/boxicons.min.css'
import './assets/vendor/venobox/venobox.css'
import './assets/vendor/owl.carousel/assets/owl.carousel.min.css'
import './assets/vendor/aos/aos.css'

// Template Main CSS File -->
import './assets/css/style.css'

//Vendor JS Files -->
// import $ from 'jquery'
import './assets/vendor/jquery/jquery.min.js'
import './assets/vendor/bootstrap/js/bootstrap.bundle.min.js'
// import './assets/vendor/jquery.easing/jquery.easing.min.js'
// import './assets/vendor/php-email-form/validate.js'
// import './assets/vendor/venobox/venobox.min.js'
// import './assets/vendor/waypoints/jquery.waypoints.min.js'
// import './assets/vendor/counterup/counterup.min.js'
// import './assets/vendor/owl.carousel/owl.carousel.min.js'
// import './assets/vendor/isotope-layout/isotope.pkgd.min.js'
//import './assets/vendor/aos/aos.js'

// Template Main JS File -->
// ./src/assets/js/main.js'
//import './assets/js/main.js'

// Google Adsense
import Ads from 'vue-google-adsense'
Vue.use(require('vue-script2'))
Vue.use(Ads.Adsense)

Vue.config.productionTip = false

// Configuration VueAnalytics
Vue.use(VueAnalytics, {
    id: 'UA-113148664-4',
    router
});


new Vue({
    render: h => h(App),
    store: store,
    router,
}).$mount('#app')