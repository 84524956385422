<template>
    <div>
      <!-- sidebar Categories -->
      <h3 class="sidebar-title">Search Shows By</h3>
      <div class="sidebar-item categories">
        <ul v-for="Cnts in RespShowsCnts.slice(0, 10)" v-bind:key="Cnts.id">
          <li><router-link :to="{name:'stage-shows',params:{ Maincatg:Cnts.catg_main}}"><a> {{Cnts.catg_main}}<span> ({{ Cnts.rec_count}})</span></a></router-link></li>
        </ul>
      </div> 
    </div>
</template>

<script>
import Vue from 'vue'
import Vueaxios from 'vue-axios'
import axios from 'axios'

Vue.use(Vueaxios,axios)

  export default {
    data() {
      return {
        RespShowsCnts: [],      
        }
      },       
    mounted()
    {
      this.ReadCountShowsByCatg();
    },
    methods: {
      ReadCountShowsByCatg(){     
        Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=ReadCountShowsByCatg')
        .then((resp)=>{
        this.RespShowsCnts=resp.data.CountShowsByCatg;
      })
      },                 
    },
  }
</script>
