<template>
<div>
    <!-- ======= Services Section ======= --> 
    <section class="services">
      <div class="container">

        <div class="row">
          <div class="col-md-6 col-lg-3 d-flex align-items-stretch" >
            <div class="icon-box icon-box-pink">
              <div class="icon"><router-link :to="{name:'events',params:{ Maincatg:'All'}}"><img src="https://storage.googleapis.com/thiramedia/images/icon_events.png" class="img-fluid" alt=""></router-link></div>
              <h4 class="title"><router-link :to="{name:'events',params:{ Maincatg:'All'}}">Upcoming Events</router-link></h4>
              <p class="description">Check out the upcoming events in your area!</p>
            </div>
          </div>

          <div class="col-md-6 col-lg-3 d-flex align-items-stretch">
            <div class="icon-box icon-box-pink">
              <div class="icon"><router-link :to="{name:'ArtForms', params:{ MainCatg:'All'}}"><img src="https://storage.googleapis.com/thiramedia/images/icon_odissi.png" class="img-fluid" alt=""></router-link></div>
              <h4 class="title"><router-link :to="{name:'ArtForms', params:{ MainCatg:'All'}}">Art Forms!</router-link></h4>
              <p class="description">Check out the various Indian Art forms!</p>
            </div>
          </div>

          <div class="col-md-6 col-lg-3 d-flex align-items-stretch" >
            <div class="icon-box icon-box-pink">
              <div class="icon"><router-link to="/events-gallery"><img src="https://storage.googleapis.com/thiramedia/images/icon_bnatyam1.png" class="img-fluid" alt=""></router-link></div>
              <h4 class="title"><router-link to="/events-gallery">Events Gallery!</router-link></h4>
              <p class="description">Check out the performing events photo Gallery!</p>
            </div>
          </div>

          <!-- <div class="col-md-6 col-lg-3 d-flex align-items-stretch" >
            <div class="icon-box icon-box-pink">
              <div class="icon"><router-link :to="{name:'Allmovies', params:{ MainCatg:'All'}}"><img src="https://storage.googleapis.com/thiramedia/images/icon_movies.png" class="img-fluid" alt=""></router-link></div>
              <h4 class="title"><router-link :to="{name:'Allmovies', params:{ MainCatg:'All'}}">Movies!</router-link></h4>           
              <p class="description">Check out the latest movies, trailers & reviews!</p>
            </div>
          </div> -->

          <div class="col-md-6 col-lg-3 d-flex align-items-stretch" >
            <div class="icon-box icon-box-pink">
              <div class="icon"><router-link :to="{name:'magazines'}"><img src="https://storage.googleapis.com/thiramedia/images/icon_articles.png" class="img-fluid" alt=""></router-link></div>
              <h4 class="title"><router-link :to="{name:'magazines'}">Magazines!</router-link></h4>           
              <p class="description">Check out the Performing arts magazines and News letters !</p>
            </div>
          </div>          
        </div>

      </div>
    </section><!-- End Services Section -->
</div>
</template>

<script>

export default {
  
}
</script>