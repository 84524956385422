<template>
    <div>
        <h3 class="sidebar-title">Related Art Forms</h3>
        <div class="sidebar-item recent-posts" v-for="Data in RelatedData.slice(0, 8)" v-bind:key="Data.id">
          <div class=" clearfix mt-4">
            <router-link :to="{name:'Art-forms-detail', params:{ SeqNo:Data.seq_no}}"><img class="rounded-circle float-left" v-bind:src="Data.title_image" :alt="Data.content_title"></router-link>
            <h4><router-link :to="{name:'Art-forms-detail', params:{ SeqNo:Data.seq_no}}">{{Data.content_title}}</router-link></h4>
            <p class="tag-line">{{Data.content_title}}</p>
          </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import Vueaxios from 'vue-axios'
import axios from 'axios'

Vue.use(Vueaxios,axios)
  export default {
    data() {
      return {
        RespArray: [],
        RelatedData: [],
        }
      },
  
    mounted()
    {
      this.ReadRecentArtforms()
    },
    methods: {
        ReadRecentArtforms(){
        Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=ReadRecentArtforms')
        .then((resp)=>{
        this.RelatedData=resp.data.RecentArtforms;
      })
      },          
    },
    computed: {      
  }
  }
</script>

<style scoped>

.page-title-text {
  color:#e75480;
  font-weight:800;
  font-size:16px;
  margin-left:10px;
}

.page-title-text p {
    margin-top: -25px;
    font-style: italic;
    font-size:14px;
    font-weight:300;
    color: #72afce;
}
</style>