<template>
  <!-- ======= Header ======= -->
  <!-- <header id="header" class="fixed-top header-transparent"> -->    
  <header id="header" v-bind:class="HeaderClass"> 
    <scriptjs/>
    <div class="thira-header"></div>
    <div class="container">
      <div class="logo float-left">
        <!-- <h1 class="text-light"><router-link :to="{name:'Home'}"><span>Thiraseela</span></router-link></h1> -->
        <router-link :to="{name:'Home'}"><img class="img-fluid" alt="Thiraseela logo" src="https://storage.googleapis.com/thiramedia/images/thira_logo.png"></router-link> 
        <!-- Uncomment below if you prefer to use an image logo -->
        <!-- <a href="index.html"><img src="assets/img/logo.png" alt="" class="img-fluid"></a>-->
      </div>

      <nav class="nav-menu float-right d-none d-lg-block">
        <ul>
          <li><router-link :to="{name:'Home'}">Home</router-link></li>
          <li><router-link :to="{name:'About-us'}">About us</router-link></li>
          <li><router-link :to="{name:'stage-shows',params:{ Maincatg:'All'}}">Stage Shows</router-link></li>
          <li><router-link :to="{name:'performers',params:{ Maincatg:'All'}}">Performers</router-link></li>
          <li><router-link :to="{name:'troupes',params:{ Maincatg:'All'}}">Troupes</router-link></li>
          <li><router-link :to="{name:'academies',params:{ Maincatg:'All'}}">Academies</router-link></li>
          <!-- <li><router-link :to="{name:'events',params:{ Maincatg:'All'}}">Events</router-link></li> -->
          <li><router-link to="/events-gallery">Gallery</router-link></li>
          <!-- <li><router-link to="/user/login">Login</router-link></li> -->
          <li v-if="user.authenticated"><router-link to="/user/dashboard">My-Account</router-link></li>
          <li v-else><router-link to="/user/login">Sign-in!</router-link></li>
          <!-- <li v-if="user.loggedIn"><router-link to="/user/login">Login</router-link></li>
          <li v-else><router-link to="" @click="logout()">logout</router-link></li>           -->

          <!-- <li><router-link to="/">Home</router-link></li> -->
          <!-- <li><a href="about.html">About Us</a></li>
          <li><a href="services.html">Services</a></li>
          <li><a href="portfolio.html">Portfolio</a></li>
          <li><a href="team.html">Team</a></li>
          <li><a href="blog.html">Blog</a></li> -->
          <!-- <li class="drop-down"><router-link :to="{name:'ArtForms', params:{ MainCatg:'All'}}">Art Forms</router-link>
            <ul>
              <li><router-link to="/blogs">Blog Template</router-link></li>
              <li><router-link :to="{name:'ArtForms', params:{ MainCatg:'Dance'}}">Dance</router-link>
              <li><router-link :to="{name:'ArtForms', params:{ MainCatg:'Dance Drama'}}">Dance Drama</router-link>
                <ul>
                  <li><router-link to="">Bhangara</router-link></li>
                  <li><router-link to="/">Art Forms</router-link></li>
                  <li><router-link to="/">Art Forms</router-link></li>
                  <li><router-link to="/">Art Forms</router-link></li>
                  <li><router-link to="/">Art Forms</router-link></li>
                </ul>
              </li>
              <li><router-link :to="{name:'ArtForms', params:{ MainCatg:'Folk Arts'}}">Folk Arts</router-link></li> 
              <li><router-link :to="{name:'ArtForms', params:{ MainCatg:'Instruments'}}">Instruments</router-link></li> 
              <li><router-link :to="{name:'ArtForms', params:{ MainCatg:'Music'}}">Music</router-link></li>
              <li><router-link :to="{name:'ArtForms', params:{ MainCatg:'Performing Arts'}}">Performing Arts</router-link></li>   
              <li><router-link :to="{name:'ArtForms', params:{ MainCatg:'Ritual Arts'}}">Ritual Arts</router-link></li>
              <li><router-link to="/">Art Forms</router-link></li>
              <li><router-link to="/">Art Forms</router-link></li>
              <li><router-link to="/">Art Forms</router-link></li>
            </ul>
          </li> -->
          <!-- <li class="drop-down"><router-link :to="{name:'awards'}">Blogs</router-link>
            <ul>
              <li><router-link :to="{name:'awards'}">Awards</router-link>
              <li><router-link :to="{name:'magazines'}">Magazines</router-link></li>
              <li><router-link :to="{name:'websites', params:{ WebType:'All'}}">Websites</router-link></li>
            </ul>
          </li>           -->
          <!-- <li><router-link to="/contact">Contact Us</router-link></li> -->
        </ul>
      </nav><!-- .nav-menu -->
    </div>
  </header><!-- End Header -->
</template>

<script>
  import { mapGetters } from "vuex";
export default {

  props: {
    HeaderClass: String,
  },
  data () {
    return {
      // ReloadComKey: 0
    }
  },
    mounted()
    {
      // this.ReloadComKey=1
    },    
  // watch: {
  //   '$route' ()
  //   {
  //     this.ReloadComKey=this.ReloadComKey+1;
  //   }
  // },    
  methods: {
   
  },
  computed: {
    // map `this.user` to `this.$store.getters.user`
    ...mapGetters({
      user: "user"
    })
    }   
  
}
</script>