<template>
<div class="admin-header">
  <b-navbar toggleable="lg"  type="dark">
    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav text-varient="white">
      <b-navbar-nav>
        <b-nav-item><router-link :to="{name:'admin-dashboard'}">Home</router-link></b-nav-item>
        <b-nav-item><router-link :to="{name:'admin-users'}">Manage Users</router-link></b-nav-item>

        <b-nav-item-dropdown text="Manage Profiles">
          <b-dropdown-item><router-link :to="{name:'admin-profiles'}">User Profiles</router-link></b-dropdown-item>
          <b-dropdown-item><router-link :to="{name:'admin-academy'}">Academies</router-link></b-dropdown-item>
          <b-dropdown-item><router-link :to="{name:'admin-stage-shows'}">Stage Shows</router-link></b-dropdown-item>
          <b-dropdown-item><router-link :to="{name:'admin-troupe'}">Troupes</router-link></b-dropdown-item>

        </b-nav-item-dropdown>

        <b-nav-item-dropdown text="Manage Contents">
          <b-dropdown-item><router-link :to="{name:'admin-awards'}">Awards</router-link></b-dropdown-item>
          <b-dropdown-item><router-link :to="{name:'admin-achievements'}">Achievements</router-link></b-dropdown-item>
          <b-dropdown-item><router-link :to="{name:'admin-gallery'}">Gallery</router-link></b-dropdown-item>
          <b-dropdown-item><router-link :to="{name:'admin-magazines'}">Magazines</router-link></b-dropdown-item>
          <b-dropdown-item><router-link :to="{name:'admin-movies'}">Movies</router-link></b-dropdown-item>
          <b-dropdown-item><router-link :to="{name:'admin-photos'}">Photos</router-link></b-dropdown-item>
          <b-dropdown-item><router-link :to="{name:'admin-static-pages'}">Static Pages</router-link></b-dropdown-item>
          <b-dropdown-item><router-link :to="{name:'admin-website'}">Website</router-link></b-dropdown-item>

        </b-nav-item-dropdown>
        <b-nav-item-dropdown text="Manage Codes">
          <b-dropdown-item><router-link :to="{name:'admin-awards'}">Places</router-link></b-dropdown-item>
          <b-dropdown-item><router-link :to="{name:'admin-awards'}">Categories</router-link></b-dropdown-item>
          <b-dropdown-item><router-link :to="{name:'admin-awards'}">Awards</router-link></b-dropdown-item>
          <b-dropdown-item><router-link :to="{name:'admin-awards'}">Codes</router-link></b-dropdown-item>
        </b-nav-item-dropdown>                    

      </b-navbar-nav>

      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto">
        <b-nav-form>
          <b-nav-item right @click="LogOut()">Logout</b-nav-item>
        </b-nav-form>

      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</div>
</template>

<style scoped>
.admin-header {
  background: rgb(94, 0, 0);
  color: #ffffff;
}
.admin-header .b-dropdown-item {
    color: #ffffff;


}
</style>

<script>
import {fb} from '../firebase'

export default {
  methods: {
      LogOut(){
        fb.auth().signOut()
          .then(() => {
            this.$router.push('/home');  
          })
      },    
  },
  
}
</script>