<template>
    <div>
      <section class="recent-perform section-bg1" >
        <div class="container">  
        <div class="row ">
          <div class="col-lg-12 section-title">
          <h2>Academies & Art Centers</h2>
        </div>           
          <div class="col-lg-3 d-flex flex-column justify-content-center p-4" v-for="Data in RecentData.slice(0, 8)" v-bind:key="Data.id">
            <div class=" clearfix">
              <router-link :to="{name:'Academy-detail', params:{ AcademyId:Data.seq_no, SlugName:Data.slug_name}}"><img class="rounded-circle float-left" v-bind:src="Data.academy_photo" :alt="Data.academy_name"></router-link>
              <h4><router-link :to="{name:'Academy-detail', params:{ AcademyId:Data.seq_no, SlugName:Data.slug_name}}">{{Data.academy_name}}</router-link></h4>
              <p class="tag-line">{{Data.academy_type}}</p>
            </div>
          </div>
          <div class="row view-all ">
            <router-link class="mx-auto" style="width: 200px;" :to="{name:'academies',params:{ Maincatg:'All'}}">View All...</router-link>
          </div>          
        </div>
        </div>
      </section>
    </div>
</template>

<script>
import Vue from 'vue'
import Vueaxios from 'vue-axios'
import axios from 'axios'

Vue.use(Vueaxios,axios)

  export default {
    data() {
      return {
        RecentData: [],
        }
      },
  watch: {
    '$route' (to)
    {
      this.ReadDtlAcademy(to.params.AcademyId);
    }
  },         
    mounted()
    {
      this.RecentAcademy()
    },
    methods: {   
        RecentAcademy(){
        Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=RecentAcademy')
        .then((resp)=>{
        this.RecentData=resp.data.RecentAcademy;
      })
      },         
    },
    computed: {      
  }
  }
</script>

<style scoped>

.recent-perform img {
    width: 80px;
    float: center;
    height: 80px;
}

/* .recent-perform {
    margin-top: 1px;
} */

.recent-perform h4 {
    font-size: 15px;
    margin-left: 89px;
    font-weight: bold;
}

.recent-perform h4 a {
    color: #e75480;
    transition: 0.3s;
}

.recent-perform h4 a:hover {
    color: #72afce;
}

.recent-perform .tag-line {
    display: block;
    margin-left: 89px;
    margin-top: -5px;
    font-style: italic;
    font-size: 14px;
    color: #72afce;
}
</style>