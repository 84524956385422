<template>
    <div>
      <section class="recent-perform section-bg1" >
        <div class="container">  
        <div class="row ">
          <div class="col-lg-12 section-title">
          <h2>Book a Stage Show</h2>
          <p> Search through a variety of stage shows and performers who can entertain your next event!</p>
        </div>           
          <div class="col-lg-4 d-flex flex-column justify-content-center p-4" v-for="Data in RecentData.slice(0, 6)" v-bind:key="Data.seq_no">
            <div class="clearfix">
              <router-link :to="{name:'stage-shows-detail', params:{ ShowId:Data.seq_no, SlugName:Data.slug_name}}"><img class="float-left" v-bind:src="Data.logo" :alt="Data.show_name"></router-link>
              <h4><router-link :to="{name:'stage-shows-detail', params:{ ShowId:Data.seq_no, SlugName:Data.slug_name}}">{{Data.show_name}}</router-link></h4>
              <p class="tag-line">{{Data.catg_main}} By {{Data.show_owner}} </p>
            </div>
          </div>
          <div class="row view-all">
            <router-link :to="{name:'stage-shows',params:{ Maincatg:'All'}}">View All...</router-link>
          </div>            
        </div>
        </div>
      </section>
    </div>
</template>

<script>
import Vue from 'vue'
import Vueaxios from 'vue-axios'
import axios from 'axios'

Vue.use(Vueaxios,axios)

  export default {
    data() {
      return {
        RecentData: [],
        }
      },
  watch: {
    '$route' (to)
    {
      this.ReadDtltroupe(to.params.EgallId);
    }
  },        
    mounted()
    {
      this.ReadRecentStageShows()
    },
    methods: {   
        ReadRecentStageShows(){
        Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=ReadRecentStageShows')
        .then((resp)=>{
        this.RecentData=resp.data.RecentStageShows;
      })
      },          
    },
    computed: {      
  }
  }
</script>

<style scoped>

.recent-perform img {
    width: 100px;
    float: center;
    height: 100px;
    padding: 2px 5px 2px 5px;
    border-radius: 15px;

}

/* .recent-perform {
    margin-top: 1px;
} */

.recent-perform h4 {
    font-size: 15px;
    font-weight: bold;
}

.recent-perform h4 a {
    color: #e75480;
    transition: 0.3s;
}

.recent-perform h4 a:hover {
    color: #72afce;
}

.recent-perform .tag-line {
    display: block;
    margin-left: 89px;
    margin-top: -5px;
    font-style: italic;
    font-size: 14px;
    color: #72afce;
}
</style>