import Vue from 'vue'
import Router from 'vue-router'
import Vuex from "vuex"
import store from '../store.js';


// Import Components for Home pages
import Home from '../components/Home'
import Home_navbar from '../components/Home_navbar'

import Home_hero from '../components/Home_hero'
import Home_service from '../components/Home_service'
import Home_whyus from '../components/Home_whyus'
import Home_features from '../components/Home_features'
import Home_footer from '../components/Home_footer'
import Home_contact from '../components/Home_contact'
import Home_welcome from '../components/Home_welcome'
import Home_portfolio from '../components/Home_portfolio'
import Home_partner from '../components/Home_partner'
import Home_testimonials from '../components/Home_testimonials'
import HomeRecentPerformers from '../components/HomeRecentPerformers'
import HomeRecentTroupes from '../components/HomeRecentTroupes'
import HomeRecentAcademies from '../components/HomeRecentAcademies'
import HomeRecentGallery from '../components/HomeRecentGallery'
import HomeRecentShows from '../components/HomeRecentShows'
import HomeRecentOnClasses from '../components/HomeRecentOnClasses'



import SideRecentPerformers from '../components/SideRecentPerformers'
import SideRecentAcademies from '../components/SideRecentAcademies'
import SideRecentTroupes from '../components/SideRecentTroupes'
import SideRecentArtForms from '../components/SideRecentArtForms'
import SideRecentOnlineClasses from '../components/SideRecentOnlineClasses'

import SideFilterPerformers from '../components/SideFilterPerformers'
import SideCatgPerformers from '../components/SideCatgPerformers'
import SideCatgAcademy from '../components/SideCatgAcademy'
import SideCatgTroupe from '../components/SideCatgTroupe'
import SideCatgStageShows from '../components/SideCatgStageShows'
import SideRecentStageShows from '../components/SideRecentStageShows'
import UsrInsSideMenu from '../components/UsrInsSideMenu'



import SideCatgEvents from '../components/SideCatgEvents'
import SideRecentGallery from '../components/SideRecentGallery'
import AdminNavbar from '../components/AdminNavbar'
import scriptjs from '../components/scriptjs'
import RefFormCatg from '../components/RefFormCatg'
import RefFormPlaces from '../components/RefFormPlaces'
import RefFormShowBy from '../components/RefFormShowBy'
import GoogleAdsense from '../components/GoogleAdsense'



import { fb } from '../firebase'

Vue.use(Router)
Vue.use(Vuex)
Vue.component('Home_navbar', Home_navbar)
Vue.component('Home_hero', Home_hero)
Vue.component('Home_service', Home_service)
Vue.component('Home_whyus', Home_whyus)
Vue.component('Home_features', Home_features)
Vue.component('Home_footer', Home_footer)
Vue.component('Home_contact', Home_contact)
Vue.component('Home_welcome', Home_welcome)
Vue.component('Home_portfolio', Home_portfolio)
Vue.component('Home_partner', Home_partner)
Vue.component('Home_testimonials', Home_testimonials)
Vue.component('HomeRecentPerformers', HomeRecentPerformers)
Vue.component('HomeRecentTroupes', HomeRecentTroupes)
Vue.component('HomeRecentAcademies', HomeRecentAcademies)
Vue.component('HomeRecentGallery', HomeRecentGallery)
Vue.component('HomeRecentShows', HomeRecentShows)
Vue.component('HomeRecentOnClasses', HomeRecentOnClasses)
Vue.component('SideRecentPerformers', SideRecentPerformers)
Vue.component('SideRecentAcademies', SideRecentAcademies)
Vue.component('SideRecentTroupes', SideRecentTroupes)
Vue.component('SideRecentArtForms', SideRecentArtForms)
Vue.component('SideRecentOnlineClasses', SideRecentOnlineClasses)
Vue.component('SideFilterPerformers', SideFilterPerformers)
Vue.component('SideCatgPerformers', SideCatgPerformers)
Vue.component('SideCatgAcademy', SideCatgAcademy)
Vue.component('SideCatgTroupe', SideCatgTroupe)
Vue.component('SideCatgStageShows', SideCatgStageShows)
Vue.component('SideRecentStageShows', SideRecentStageShows)
Vue.component('SideCatgEvents', SideCatgEvents)
Vue.component('SideRecentGallery', SideRecentGallery)
Vue.component('AdminNavbar', AdminNavbar)
Vue.component('scriptjs', scriptjs)
Vue.component('RefFormCatg', RefFormCatg)
Vue.component('RefFormPlaces', RefFormPlaces)
Vue.component('RefFormShowBy', RefFormShowBy)
Vue.component('UsrInsSideMenu', UsrInsSideMenu)
Vue.component('GoogleAdsense', GoogleAdsense)

// const store = new Vuex.Store({
//     state: {
//         authenticated: false
//     },
//     mutations: {
//         setAuthentication(state, status) {
//             state.authenticated = status;
//         }
//     }
// });

const router = new Router({
    // const router = new VueRouter({
    mode: 'history',
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    },
    routes: [
        { path: '/', redirect: '/Home' },
        { path: '*', redirect: '/Home' },
        { path: '/*.php', redirect: '/Home' },
        { path: '/*.php*', redirect: '/Home' },

        {
            path: '/Home',
            name: 'Home',
            components: {
                default: Home,
                Home_navbar: Home_navbar,
            },
        },
        {
            path: '/user',
            name: 'user',
            component: () =>
                import ( /* webpackChunkName: "login" */ "../pages/UserDashboardHome"),

            children: [{
                    path: 'login',
                    name: 'login',
                    component: () =>
                        import ( /* webpackChunkName: "dashboard" */ "../components/UserLogin"),
                },
                // {
                //     path: 'login-sendlink',
                //     name: 'login-sendlink',
                //     component: () =>
                //         import ( /* webpackChunkName: "dashboard" */ "../components/UserPswdlessLogin"),

                // },
                {
                    path: 'contact-us',
                    name: 'contact-us',
                    component: () =>
                        import ( /* webpackChunkName: "dashboard" */ "../components/Home_contact"),
                },
                {
                    path: 'confirm-login',
                    name: 'confirm-login',
                    component: () =>
                        import ( /* webpackChunkName: "dashboard" */ "../components/UserConfirmLogin"),
                },
                // {
                //     path: 'resetpswd',
                //     name: 'resetpswd',
                //     component: () =>
                //         import ( /* webpackChunkName: "dashboard" */ "../components/UserResetPassword"),

                // },
                {
                    path: 'dashboard',
                    name: 'dashboard',
                    component: () =>
                        import ( /* webpackChunkName: "dashboard" */ "../components/UserDashboard"),
                    meta: { requiresAuth: true }

                },
                {
                    path: 'update-PProfile/:SeqNo',
                    name: 'update-PProfile',
                    component: () =>
                        import ( /* webpackChunkName: "dashboard" */ "../components/UsrUpdUserProfile"),
                    meta: { requiresAuth: true }

                },
                {
                    path: 'update-TProfile/:SeqNo',
                    name: 'update-TProfile',
                    component: () =>
                        import ( /* webpackChunkName: "dashboard" */ "../components/UsrUpdTroupeProfile"),
                    meta: { requiresAuth: true }

                },
                {
                    path: 'update-AProfile/:SeqNo',
                    name: 'update-AProfile',
                    component: () =>
                        import ( /* webpackChunkName: "dashboard" */ "../components/UsrUpdAcademyProfile"),
                    meta: { requiresAuth: true }

                },
                {
                    path: 'update-StageShow/:SeqNo',
                    name: 'update-StageShow',
                    component: () =>
                        import ( /* webpackChunkName: "dashboard" */ "../components/UsrUpdStageShows"),
                    meta: { requiresAuth: true }

                },
                {
                    path: 'update-promotions/:SeqNo',
                    name: 'update-promotions',
                    component: () =>
                        import ( /* webpackChunkName: "dashboard" */ "../components/UsrUpdPromotions"),
                    meta: { requiresAuth: true }

                },
                {
                    path: 'Photos',
                    name: 'submit-photos',
                    component: () =>
                        import ( /* webpackChunkName: "submit-photos" */ "../components/UsrInsPhotos"),
                    // meta: { requiresAuth: true },
                },
                {
                    path: 'Achievements',
                    name: 'submit-achievements',
                    component: () =>
                        import ( /* webpackChunkName: "submit-achievements" */ "../components/UsrInsAchievements"),
                    // meta: { requiresAuth: true },
                },
                {
                    path: 'Events',
                    name: 'submit-events',
                    component: () =>
                        import ( /* webpackChunkName: "submit-Events" */ "../components/UsrInsEvents"),
                    // meta: { requiresAuth: true },
                },
                {
                    path: 'StageShows',
                    name: 'submit-stage-shows',
                    component: () =>
                        import ( /* webpackChunkName: "submit-StageShows" */ "../components/UsrInsStageShows"),
                    // meta: { requiresAuth: true },
                },
                {
                    path: 'Promotions',
                    name: 'submit-promotions',
                    component: () =>
                        import ( /* webpackChunkName: "submit-promotions" */ "../components/UsrInsPromotions"),
                    // meta: { requiresAuth: true },
                },
                {
                    path: 'user-portfolio',
                    name: 'submit-user-portfolio',
                    component: () =>
                        import ( /* webpackChunkName: "submit-user-portfolio" */ "../components/UsrInsUserProfile"),
                    // meta: { requiresAuth: true },
                },
                {
                    path: 'troupe-portfolio',
                    name: 'submit-troupe-portfolio',
                    component: () =>
                        import ( /* webpackChunkName: "submit-troupe-portfolio" */ "../components/UsrInsTroupeProfile"),
                    // meta: { requiresAuth: true },
                },
                {
                    path: 'academy-portfolio',
                    name: 'submit-academy-portfolio',
                    component: () =>
                        import ( /* webpackChunkName: "submit-academy-portfolio" */ "../components/UsrInsAcademyProfile"),
                    // meta: { requiresAuth: true },
                },
            ]
        },
        {
            path: '/About-us',
            name: 'About-us',
            component: () =>
                import ( /* webpackChunkName: "About-us" */ "../pages/Blog_about_us")
                // components: {
                //     default: Blog_about_us,
                //     Home_navbar: Home_navbar,
                // },
        },
        {
            path: '/contact',
            name: 'contact',
            component: () =>
                import ( /* webpackChunkName: "contact" */ "../pages/Blog_contact_us")
                // components: {
                //     default: Blog_contact_us,
                //     Home_navbar: Home_navbar,
                // },
        },
        {
            path: '/blogs',
            name: 'Blog_template',
            component: () =>
                import ( /* webpackChunkName: "blogs" */ "../components/Blog_template")
                // components: {
                //     default: Blog_template,
                //     Home_navbar: Home_navbar,
                // },
        },
        {
            path: '/Art-forms/:MainCatg',
            name: 'ArtForms',
            component: () =>
                import ( /* webpackChunkName: "contact" */ "../pages/ListArtForms")
                // components: {
                //     default: ListArtForms,
                //     Home_navbar: Home_navbar,
                // },
        },
        {
            path: '/Art-forms-detail/:SeqNo',
            name: 'Art-forms-detail',
            component: () =>
                import ( /* webpackChunkName: "Art-forms-detail" */ "../pages/DtlArtforms")
                // components: {
                //     default: DtlArtforms,
                //     Home_navbar: Home_navbar,
                // },
        },
        {
            path: '/performers/:Maincatg',
            name: 'performers',
            component: () =>
                import ( /* webpackChunkName: "performers" */ "../pages/ListPerformers")
                // components: {
                //     default: ListPerformers,
                //     Home_navbar: Home_navbar,
                // },
        },
        {
            path: '/performers-detail/:ProfileId/:SlugName',
            name: 'performers-detail',
            component: () =>
                import ( /* webpackChunkName: "performers-detail" */ "../pages/DtlPerformers")
                // components: {
                //     default: DtlPerformers,
                //     Home_navbar: Home_navbar,
                // },
        },
        {
            path: '/rahulchakyar',
            name: 'rahulchakyar',
            component: () =>
                import ( /* webpackChunkName: "rahulchakyar" */ "../pages/rahulchakyar")
                // components: {
                //     default: rahulchakyar,
                //     Home_navbar: Home_navbar,
                // },
        },            
        {
            path: '/academies/:Maincatg',
            name: 'academies',
            component: () =>
                import ( /* webpackChunkName: "academies" */ "../pages/ListAcademy")
                // components: {
                //     default: ListAcademy,
                //     Home_navbar: Home_navbar,
                // },
        },
        {
            path: '/Academy-detail/:AcademyId/:SlugName',
            name: 'Academy-detail',
            component: () =>
                import ( /* webpackChunkName: "Academy-detail" */ "../pages/DtlAcademy")
                // components: {
                //     default: DtlAcademy,
                //     Home_navbar: Home_navbar,
                // },
        },
        {
            path: '/troupes/:Maincatg',
            name: 'troupes',
            component: () =>
                import ( /* webpackChunkName: "troupes" */ "../pages/ListTroupe")
                // components: {
                //     default: ListTroupe,
                //     Home_navbar: Home_navbar,
                // },
        },
        {
            path: '/troupes-detail/:TroupeId/:SlugName',
            name: 'troupes-detail',
            component: () =>
                import ( /* webpackChunkName: "troupes-detail" */ "../pages/DtlTroupe")
                // components: {
                //     default: DtlTroupe,
                //     Home_navbar: Home_navbar,
                // },
        },
        {
            path: '/awards',
            name: 'awards',
            component: () =>
                import ( /* webpackChunkName: "awards" */ "../pages/ListAwards")
                // components: {
                //     default: ListAwards,
                //     Home_navbar: Home_navbar,
                // },
        },
        {
            path: '/awards-detail/:AwardId',
            name: 'awards-detail',
            component: () =>
                import ( /* webpackChunkName: "awards-detail" */ "../pages/DtlAwards")
                // components: {
                //     default: DtlAwards,
                //     Home_navbar: Home_navbar,
                // },
        },
        {
            path: '/websites/:WebType',
            name: 'websites',
            component: () =>
                import ( /* webpackChunkName: "websites" */ "../pages/ListWebsite")
                // components: {
                //     default: ListWebsite,
                //     Home_navbar: Home_navbar,
                // },
        },
        {
            path: '/magazines',
            name: 'magazines',
            component: () =>
                import ( /* webpackChunkName: "magazines" */ "../pages/Listmagazine")
                // components: {
                //     default: Listmagazine,
                //     Home_navbar: Home_navbar,
                // },
        },
        {
            path: '/magazine-detail/:MagazineId',
            name: 'magazine-detail',
            component: () =>
                import ( /* webpackChunkName: "magazine-detail" */ "../pages/DtlMagazine")
                // components: {
                //     default: DtlMagazine,
                //     Home_navbar: Home_navbar,
                // },
        },
        {
            path: '/events-gallery',
            name: 'events-gallery',
            component: () =>
                import ( /* webpackChunkName: "events-gallery" */ "../pages/ListEventGallery")
                // components: {
                //     default: ListEventGallery,
                //     Home_navbar: Home_navbar,
                // },
        },
        {
            path: '/events-gallery-detail/:EgallId',
            name: 'events-gallery-detail',
            component: () =>
                import ( /* webpackChunkName: "events-gallery-detail" */ "../pages/DtlEventGallery")
                // components: {
                //     default: DtlEventGallery,
                //     Home_navbar: Home_navbar,
                // },
        },
        {
            path: '/event-photos',
            name: 'event-photos',
            component: () =>
                import ( /* webpackChunkName: "event-photos" */ "../pages/ListUserPhotos")
                // components: {
                //     default: ListUserPhotos,
                //     Home_navbar: Home_navbar,
                // },
        },
        {
            path: '/achievement',
            name: 'achievement',
            component: () =>
                import ( /* webpackChunkName: "achievement" */ "../pages/ListUserAchievement")
                // components: {
                //     default: ListUserAchievement,
                //     Home_navbar: Home_navbar,
                // },
        },
        {
            path: '/stage-shows/:Maincatg',
            name: 'stage-shows',
            component: () =>
                import ( /* webpackChunkName: "stage-shows" */ "../pages/ListStageShows")
                // components: {
                //     default: ListStageShows,
                //     Home_navbar: Home_navbar,
                // },
        },
        {
            path: '/stage-shows-detail/:ShowId/:SlugName',
            name: 'stage-shows-detail',
            component: () =>
                import ( /* webpackChunkName: "stage-shows-detail" */ "../pages/DtlStageShows")
                // components: {
                //     default: DtlStageShows,
                //     Home_navbar: Home_navbar,
                // },
        },
        {
            path: '/online-classes/:Maincatg',
            name: 'online-classes',
            component: () =>
                import ( /* webpackChunkName: "online-classes" */ "../pages/ListOnlineClasses")
        },
        {
            path: '/online-classes-detail/:SeqNo/:SlugName',
            name: 'online-classes-detail',
            component: () =>
                import ( /* webpackChunkName: "stage-shows-detail" */ "../pages/DtlOnlineClasses")
        },
        {
            path: '/movies/:MainCatg',
            name: 'Allmovies',
            component: () =>
                import ( /* webpackChunkName: "Allmovies" */ "../pages/ListMovies")
                // components: {
                //     default: ListMovies,
                //     Home_navbar: Home_navbar,
                // },
        },
        {
            path: '/movie-detail/:MovieId',
            name: 'movie-detail',
            component: () =>
                import ( /* webpackChunkName: "movie-detail" */ "../pages/DtlMovies")
                // components: {
                //     default: DtlMovies,
                //     Home_navbar: Home_navbar,
                // },
        },
        {
            path: '/events/:Maincatg',
            name: 'events',
            component: () =>
                import ( /* webpackChunkName: "events" */ "../pages/ListEvents")
                // components: {
                //     default: ListEvents,
                //     Home_navbar: Home_navbar,
                // },
        },
        {
            path: '/ThiraAdmin',
            name: 'ThiraAdmin',
            component: () =>
                import ( /* webpackChunkName: "ThiraAdmin" */ "../pages/AdminLogin"),
            meta: { requiresAuth: true }
        },
        // {
        //     path: '/share',
        //     name: 'share-with-us',
        //     component: () =>
        //         import ( /* webpackChunkName: "share-with-us" */ "../pages/UsrInsSideMenu"),
        //     // meta: { requiresAuth: true },

        //     children: [{
        //             path: 'Photos',
        //             name: 'submit-photos',
        //             component: () =>
        //                 import ( /* webpackChunkName: "submit-photos" */ "../components/UsrInsPhotos"),
        //             // meta: { requiresAuth: true },
        //         },
        //         {
        //             path: 'Achievements',
        //             name: 'submit-achievements',
        //             component: () =>
        //                 import ( /* webpackChunkName: "submit-achievements" */ "../components/UsrInsAchievements"),
        //             // meta: { requiresAuth: true },
        //         },
        //         {
        //             path: 'Events',
        //             name: 'submit-events',
        //             component: () =>
        //                 import ( /* webpackChunkName: "submit-Events" */ "../components/UsrInsEvents"),
        //             // meta: { requiresAuth: true },
        //         },
        //         {
        //             path: 'StageShows',
        //             name: 'submit-stage-shows',
        //             component: () =>
        //                 import ( /* webpackChunkName: "submit-StageShows" */ "../components/UsrInsStageShows"),
        //             // meta: { requiresAuth: true },
        //         },
        //         {
        //             path: 'Promotions',
        //             name: 'submit-promotions',
        //             component: () =>
        //                 import ( /* webpackChunkName: "submit-promotions" */ "../components/UsrInsPromotions"),
        //             // meta: { requiresAuth: true },
        //         },
        //         {
        //             path: 'user-portfolio',
        //             name: 'submit-user-portfolio',
        //             component: () =>
        //                 import ( /* webpackChunkName: "submit-user-portfolio" */ "../components/UsrInsUserProfile"),
        //             // meta: { requiresAuth: true },
        //         },
        //         {
        //             path: 'troupe-portfolio',
        //             name: 'submit-troupe-portfolio',
        //             component: () =>
        //                 import ( /* webpackChunkName: "submit-troupe-portfolio" */ "../components/UsrInsTroupeProfile"),
        //             // meta: { requiresAuth: true },
        //         },
        //         {
        //             path: 'academy-portfolio',
        //             name: 'submit-academy-portfolio',
        //             component: () =>
        //                 import ( /* webpackChunkName: "submit-academy-portfolio" */ "../components/UsrInsAcademyProfile"),
        //             // meta: { requiresAuth: true },
        //         },
        //     ]
        // },
        {
            path: '/admin',
            name: 'admin-dashboard',
            component: () =>
                import ( /* webpackChunkName: "admin-dashboard" */ "../pages/AdminDashboard"),
            meta: { requiresAuth: true },
            // components: {
            //     default: ,
            //     Home_navbar: Home_navbar,
            // },
            children: [{
                    path: 'Home',
                    name: 'admin-home',
                    component: () =>
                        import ( /* webpackChunkName: "AdminHome" */ "../components/AdminHome"),
                    meta: { requiresAuth: true },
                },
                {
                    path: 'Users',
                    name: 'admin-users',
                    component: () =>
                        import ( /* webpackChunkName: "AdminUsers" */ "../components/AdminUsers"),
                    meta: { requiresAuth: true },
                },
                {
                    path: 'Website',
                    name: 'admin-website',
                    component: () =>
                        import ( /* webpackChunkName: "AdminWebsite" */ "../components/AdminWebsite"),
                    meta: { requiresAuth: true },
                },
                {
                    path: 'Awards',
                    name: 'admin-awards',
                    component: () =>
                        import ( /* webpackChunkName: "AdminAwards" */ "../components/AdminAwards"),
                    meta: { requiresAuth: true },
                },
                {
                    path: 'Magazines',
                    name: 'admin-magazines',
                    component: () =>
                        import ( /* webpackChunkName: "AdminMagazine" */ "../components/AdminMagazine"),
                    meta: { requiresAuth: true },
                },
                {
                    path: 'Static-pages',
                    name: 'admin-static-pages',
                    component: () =>
                        import ( /* webpackChunkName: "AdminStaticPages" */ "../components/AdminStaticPages"),
                    meta: { requiresAuth: true },
                },
                {
                    path: 'Movies',
                    name: 'admin-movies',
                    component: () =>
                        import ( /* webpackChunkName: "AdminMovies" */ "../components/AdminMovies"),
                    meta: { requiresAuth: true },
                },
                {
                    path: 'Photos',
                    name: 'admin-photos',
                    component: () =>
                        import ( /* webpackChunkName: "AdmDashPhotos" */ "../components/AdmDashPhotos"),
                    meta: { requiresAuth: true },
                },
                {
                    path: 'Achievements',
                    name: 'admin-achievements',
                    component: () =>
                        import ( /* webpackChunkName: "AdminAchievements" */ "../components/AdminAchievements"),
                    meta: { requiresAuth: true },
                },
                {
                    path: 'Gallery',
                    name: 'admin-gallery',
                    component: () =>
                        import ( /* webpackChunkName: "AdmDashPhotos" */ "../components/AdmDashPhotos"),
                    meta: { requiresAuth: true },
                },
                {
                    path: 'Profiles',
                    name: 'admin-profiles',
                    component: () =>
                        import ( /* webpackChunkName: "AdminPerformer" */ "../components/AdminPerformer"),
                    meta: { requiresAuth: true },
                },
                {
                    path: 'Troupe',
                    name: 'admin-troupe',
                    component: () =>
                        import ( /* webpackChunkName: "AdminTroupe" */ "../components/AdminTroupe"),
                    meta: { requiresAuth: true },
                },
                {
                    path: 'Academy',
                    name: 'admin-academy',
                    component: () =>
                        import ( /* webpackChunkName: "AdminAcademy" */ "../components/AdminAcademy"),
                    meta: { requiresAuth: true },
                },
                {
                    path: 'Stage-Shows',
                    name: 'admin-stage-shows',
                    component: () =>
                        import ( /* webpackChunkName: "AdminStageShows" */ "../components/AdminStageShows"),
                    meta: { requiresAuth: true },
                },
            ]
        },
    ]
});

router.beforeEach(async(to, from, next) => {
    //console.log('Executed beforeEach')
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    if (requiresAuth && !await fb.getCurrentUser()) {
        next('/user/login');
    } else {
        next();
    }
});

fb.getCurrentUser = () => {
    //console.log('Executed getCurrentUser')
    return new Promise((resolve, reject) => {
        const unsubscribe = fb.auth().onAuthStateChanged(user => {
            unsubscribe();
            resolve(user);
        }, reject);
    })
};

fb.auth().onAuthStateChanged(user => {
    store.dispatch("fetchUser", user);
    //console.log('Executed dispatch', user)

});

export default router;
