<template>
    <div>
        <h3 class="sidebar-title">Recent Academies</h3>
        <div class="sidebar-item recent-posts" v-for="Data in RecentData.slice(0, 8)" v-bind:key="Data.id">
            <div class="post-item clearfix mt-4">
              <router-link :to="{name:'Academy-detail', params:{ AcademyId:Data.seq_no, SlugName:Data.slug_name}}"><img class="rounded-circle float-left" v-bind:src="Data.academy_photo" :alt="Data.academy_name"></router-link>
              <h4><router-link :to="{name:'Academy-detail', params:{ AcademyId:Data.seq_no, SlugName:Data.slug_name}}">{{Data.academy_name}}</router-link></h4>
              <p class="tag-line">{{Data.academy_type}}</p>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import Vueaxios from 'vue-axios'
import axios from 'axios'

Vue.use(Vueaxios,axios)

  export default {
    data() {
      return {
        RecentData: [],
        }
      },
  watch: {
    '$route' (to)
    {
      this.ReadDtlAcademy(to.params.AcademyId);
    }
  },         
    mounted()
    {
      this.RecentAcademy()
    },
    methods: {   
        RecentAcademy(){
        Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=RecentAcademy')
        .then((resp)=>{
        this.RecentData=resp.data.RecentAcademy;
      })
      },         
    },
    computed: {      
  }
  }
</script>
