<template>
  <div> 
<!-- <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script> -->
<!-- thira_side_vert3 -->
<ins class="adsbygoogle"
     style="display:block"
     data-ad-client="ca-pub-8513650473853183"
     :data-ad-slot="AdSlot"
     data-ad-format="auto"
     data-full-width-responsive="true"></ins>
  </div>
</template>

<script>

  export default {
  props: {
    AdSlot: String
  },    
    data() {
      return {
                  
      }
    },

    mounted()
    {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    },
  }
</script>

