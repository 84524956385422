<template>
    <div>
      <section class="recent-perform section-bg" >
        <div class="container">  
        <div class="row ">
          <div class="col-lg-12 section-title">
          <h2>Professional Troupes</h2>
          <!-- <p> Search through a variety of stage shows and performers who can entertain your next event!</p> -->
        </div>           
          <div class="col-lg-3 d-flex flex-column justify-content-center p-4" v-for="Data in RecentData.slice(0, 8)" v-bind:key="Data.id">
            <div class=" clearfix">
              <router-link :to="{name:'troupes-detail', params:{ TroupeId:Data.seq_no, SlugName:Data.slug_name}}"><img class="rounded-circle float-left" v-bind:src="Data.troupe_photo" :alt="Data.troupe_name"></router-link>
              <h4><router-link :to="{name:'troupes-detail', params:{ TroupeId:Data.seq_no, SlugName:Data.slug_name}}">{{Data.troupe_name}}</router-link></h4>
              <p class="tag-line">{{Data.troupe_pgm_name}}</p>
            </div>
          </div>
          <div class="row view-all">
            <router-link :to="{name:'troupes',params:{ Maincatg:'All'}}">View All...</router-link>
          </div>            
        </div>
        </div>
      </section>
    </div>
</template>

<script>
import Vue from 'vue'
import Vueaxios from 'vue-axios'
import axios from 'axios'

Vue.use(Vueaxios,axios)

  export default {
    data() {
      return {
        RecentData: [],
        }
      },
  watch: {
    '$route' (to)
    {
      this.ReadDtltroupe(to.params.TroupeId);
    }
  },        
    mounted()
    {
      this.RecentTroupe()
    },
    methods: {   
        RecentTroupe(){
        Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=RecentTroupe')
        .then((resp)=>{
        this.RecentData=resp.data.RecentTroupe;
      })
      },          
    },
    computed: {      
  }
  }
</script>

<style scoped>

.recent-perform img {
    width: 80px;
    float: center;
    height: 80px;
}

/* .recent-perform {
    margin-top: 1px;
} */

.recent-perform h4 {
    font-size: 15px;
    margin-left: 89px;
    font-weight: bold;
}

.recent-perform h4 a {
    color: #e75480;
    transition: 0.3s;
}

.recent-perform h4 a:hover {
    color: #72afce;
}

.recent-perform .tag-line {
    display: block;
    margin-left: 89px;
    margin-top: -5px;
    font-style: italic;
    font-size: 14px;
    color: #72afce;
}
</style>