<template>
  <div id="app">
    <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
    <!-- <router-view name="Home_navbar"></router-view> -->
    <router-view/>
  </div>
</template>

<script>
// import $ from 'jquery'
export default {
  name: 'App',
  components: {
  },
metaInfo() {
        return { 
            title: "Thiraseela.com :: Online Program Booking | Artist Portfolios | Book Stage Shows | Performing Arts | Professional Troupes & Academies",
            meta: [
                { name: 'description', content:  'Thiraseela.com is a digit paltform to connect All Artist, Performers, Stage programs with the Art lovers & event organizers.'},
                { property: 'og:title', content: "Thiraseela.com :: Online Program Booking | Artist Portfolios | Book Stage Shows | Performing Arts | Professional Troupes & Academies"},
                { property: 'og:site_name', content: 'Thiraseela.com'},
                {property: 'og:type', content: 'website'},    
                {name: 'robots', content: 'index,follow'} 
            ]
        }
    }  
}
</script>

<style>
</style>
